
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DataTable from '@/components/DataTable/index.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import { handleError } from '@vue/runtime-dom'
import Inventory from '@/models/Inventory'
import WebMessage from '@/models/WebMessage'
import { groupBy } from 'lodash'

@Component({
  components: {
    Widget,
    SelectPicker,
    DataTable,
    FooterNav,
  },
})
export default class NotificationAction extends ViewModel {
  @Prop()
  public notification_key!: string

  public loading: boolean = true

  public selected: any = []

  public inventories: any = []

  public status_list = [
    {
      text: 'Dismiss for 2h',
      value: 'dismiss',
    },
    {
      text: 'Resolved',
      value: 'resolved',
    },
    {
      text: 'Continue to monitor',
      value: 'none',
    },
  ]

  public fields: any = [
    {
      key: 'name',
      label: 'Adunit',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      show: true,
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',
      thStyle: { width: '20rem' },
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      show: true,
      tdClass: 'align-middle text-center',
      thClass: 'align-middle text-center',

      thStyle: { width: '15rem' },
    },
  ]

  public mounted() {
    this.loading = false

    this.sync()
  }

  public sync() {
    Inventory.notifications().then((response: any) => {
      if (!response) {
        WebMessage.warning('Notification not found')
        this.cancel()
      }
      this.loading = false

      this.inventories = response
    })
  }

  public confirmActions() {
    this.loading = true

    let payload = groupBy(this.inventories, 'action')
    Inventory.notificationsDismiss(payload).then(() => {
      WebMessage.success('Notification settings updated successfully!')
      this.sync()
    })
  }

  public cancel() {
    this.$router.push({ name: 'Inventories' })
  }
}
